import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getOldPYEAddress = () => {
  return getAddress(addresses.oldPYE)
}
export const getOldAppleAddress = () => {
  return getAddress(addresses.oldApple)
}
export const getOldCherryAddress = () => {
  return getAddress(addresses.oldCherry)
}
export const getOldForceAddress = () => {
  return getAddress(addresses.oldForce)
}
export const getOldMINIAddress = () => {
  return getAddress(addresses.oldMINI)
}
export const getOldBULLAddress = () => {
  return getAddress(addresses.oldBULL)
}
export const getOldTrickAddress = () => {
  return getAddress(addresses.oldTrick)
}
export const getOldTreatAddress = () => {
  return getAddress(addresses.oldTreat)
}
export const getOldFuelAddress = () => {
  return getAddress(addresses.oldFuel)
}
export const getOldGravityAddress = () => {
  return getAddress(addresses.oldGravity)
}
export const getOldRideAddress = () => {
  return getAddress(addresses.oldRide)
}
export const getOldChargeAddress = () => {
  return getAddress(addresses.oldCharge)
}
export const getPYEAddress = () => {
  return getAddress(addresses.pye)
}
export const getAppleAddress = () => {
  return getAddress(addresses.apple)
}
export const getCherryAddress = () => {
  return getAddress(addresses.cherry)
}
export const getPeachAddress = () => {
  return getAddress(addresses.peach)
}
export const getOldV1PYEAddress = () => {
  return getAddress(addresses.v1Pye)
}
export const getOldCreamPYEAddress = () => {
  return getAddress(addresses.creampye)
}
export const getOldV1ForceAddress = () => {
  return getAddress(addresses.v1Force)
}
export const getOldV1AppleAddress = () => {
  return getAddress(addresses.v1Apple)
}
export const getOldV1CherryAddress = () => {
  return getAddress(addresses.v1Cherry)
}
export const getOldV1BullAddress = () => {
  return getAddress(addresses.v1Bull)
}
export const getOldV1TreasureAddress = () => {
  return getAddress(addresses.v1Treasure)
}
export const getMigrationAddress= () => {
  return getAddress(addresses.migration)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
